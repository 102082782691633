import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { audience, clientId, domain, redirectUri } from "src/constants/auth0";
import VerifyEmail from "src/ui/pages/VerifyEmail";
import { Auth0Dialog } from "../ui/components/auth0/auth0Dialog";

interface Auth0LoaderProps {
  children: React.ReactNode;
}

interface Auth0ProviderWithConfigProps {
  children: React.ReactNode;
}

// Note: This can be moved into a separate file, but leaving it here makes the Auth0 SDK integration
// more self-contained
const Auth0Loader = ({ children }: PropsWithChildren<Auth0LoaderProps>) => {
  const { isLoading, error } = useAuth0();
  if (isLoading) {
    return (
      <Auth0Dialog>
        <Box
          py={2.4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress thickness={3}></CircularProgress>
        </Box>
      </Auth0Dialog>
    );
  }

  if (error) {
    const data = {
      userId: "",
      email: "",
      errorMessage: "",
    };
    const msg: string = error?.message || "";
    if (error?.message.includes("~")) {
      const keyValuePairs = msg.split("~");
      keyValuePairs.forEach((pair) => {
        const [key, value] = pair.split("=");
        data[key] = value;
      });
    }
    if (data.userId) {
      // If the userId is present, then the user hasn't verified their email
      return (
        <VerifyEmail
          msg={data.errorMessage}
          userId={data.userId}
          email={data.email}
        />
      );
    }

    return (
      <Auth0Dialog title="Initialisation Error">
        <Stack mt={1.6} spacing={1.6}>
          <Typography fontSize={14}>
            Sorry, something went wrong while initialising the application.
          </Typography>
          <Typography variant={"baseBold"}>{msg}</Typography>
        </Stack>
      </Auth0Dialog>
    );
  }

  return <>{children}</>;
};

export const Auth0ProviderWithConfig = ({
  children,
}: PropsWithChildren<Auth0ProviderWithConfigProps>): JSX.Element | null => {
  if (!(domain && clientId && redirectUri)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      audience={audience}
      useRefreshTokens={true}
    >
      <Auth0Loader>{children}</Auth0Loader>
    </Auth0Provider>
  );
};
